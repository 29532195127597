<template>
  <div class="boxUs">
    <el-row class="dash-board">
    </el-row>
    <el-button type="primary" @click="getOne" class="inquire">查询</el-button>
    <el-button type="warning" @click="reset" class="reset">重置</el-button>
    <el-button type="primary" @click="adduser" v-permission="['Organization:add']" class="adduser">+ 添加学员</el-button>
    <p class="zhuangtai" style="font-size: 12px">状态:</p>
    <p class="name" style="font-size: 12px">登录名:</p>
    <p class="time" style="font-size: 12px">部门名称:</p>
    <p class="nameTwo" style="font-size: 12px">姓名:</p>
    <el-input v-model="userName" class="in2" placeholder="请输入登录名"></el-input>
    <el-input v-model="depName" class="data" placeholder="请输入部门名称"></el-input>
    <el-input v-model="stuName" class="opst4" placeholder="请输入姓名"></el-input>
    <!-- 状态 -->
    <el-select v-model="activeFlag" class="opst3">
      <el-option v-for="(i) in statelist" :key="i.value" :value="i.id" :label="i.value"></el-option>
    </el-select>
    <!-- 列表数据 -->
    <el-row class="bg">
    <el-table
      :data="tableData"
      style="width: 100%;">
      <el-table-column
        label="单位名称"
        align="center"
        width="250">
        <template slot-scope="scope">
          <span>{{ scope.row.depName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="登录名"
        align="center"
        width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.loginName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="姓名"
        align="center"
        width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.stuName }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="性别"
        align="center"
        width="80">
        <template slot-scope="scope">
          <span v-show="scope.row.sex == 0">男</span>
          <span v-show="scope.row.sex == 1">女</span>
        </template>
      </el-table-column>

      <el-table-column
        label="出生日期"
        align="center"
        width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.stuBirth }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="职级"
        align="center"
        width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.duty }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="干部层级"
        align="center"
        width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.stuLeadDuty }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="当前职务"
        align="center"
        width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.stuDuty }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="状态"
        align="center"
        width="80">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.activeFlag == 1">已启用</el-tag>
          <el-tag type="danger" v-if="scope.row.activeFlag == 2">已禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" v-permission="['Organization:edit']" @click="update(scope.row)">修改</el-button>
          <el-button size="mini" type="primary" @click="password(scope.row.userId)">重置密码</el-button>
           <el-button size="mini" type="danger" v-permission="['Organization:delete']" @click="userDelete(scope.row)">删 除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-row>
<!-- 新增学员 -->
    <el-dialog
      title="新增学员"
      :visible.sync="adduserVisible"
      width="700"
      center>
      <el-form
        :model="ruleForm2"
        status-icon
        :rules="rules2"
        ref="ruleForm2"
        label-width="0"
        class="demo-ruleForm"
      >
        <!-- <el-form-item prop="username" label="用户名" :label-width="formLabelWidth">
          <el-input v-model="ruleForm2.username" auto-complete="off" placeholder="请输入11位数字的用户名"></el-input>
        </el-form-item> -->
        <el-form-item prop="phone" label="手机号" :label-width="formLabelWidth">
          <el-input v-model="ruleForm2.phone" auto-complete="off" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item prop="pass" label="密码" :label-width="formLabelWidth">
          <el-input type="password" v-model="ruleForm2.pass" auto-complete="off" placeholder="输入密码必须由小写字母、大写字母、数字、特殊符号"></el-input>
        </el-form-item>
        <el-form-item prop="checkPass" label="确认密码" :label-width="formLabelWidth">
          <el-input type="password" v-model="ruleForm2.checkPass" auto-complete="off" placeholder="确认密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="adduserVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm2')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改" :visible.sync="adduserVisible2" width="1200" append-to-body>
      <el-form ref="form" :model="form" :rules="rules1" label-position="top">
        <el-row>
          <el-col :span="8">
            <el-form-item label="单位" prop="depName">
              <el-cascader
                  :options="showTreeByRedisList"
                  :props="optionProps"
                  v-model="form.depName"
                  @change="handleChange"
                  :clearable="true"
                  change-on-select
                  :filterable="true">
                </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="姓名" prop="stuName">
              <el-input v-model="form.stuName" style="width: 200px;"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" prop="stuPhone">
              <el-input v-model="form.stuPhone" style="width: 200px;"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别" prop="sex">
              <el-select v-model="form.sex">
                <el-option label="男" :value="0+''"></el-option>
                <el-option label="女" :value="1+''"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出生日期" prop="stuBirth">
              <el-date-picker type="month" v-model="form.stuBirth" value-format="yyyy-MM"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="干部层级" prop="stuLeadDuty">
              <el-select v-model="form.stuLeadDuty">
                <el-option v-for="i in AllByIdList" :label="i.value" :value="i.id" :key="i.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="职级" prop="duty">
               <el-cascader
                 :change-on-select="true"
                 :props="optionProps"
                 :options="showTreesByRedisList"
                 :clearable="true"
                 :show-all-levels="false"
                 v-model="form.duty"
                 :filterable="true"
                 style="width: 90%" ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="当前职务" prop="stuDuty">
              <el-input v-model="form.stuDuty" style="width: 200px;"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: center;">
        <el-button type="primary" @click="submitUpdate('form')">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
     <!-- 删除学员 -->
    <el-dialog
      title="删除学员"
      :visible.sync="delStuUserVisible"
      width="700"
      center>
      <el-form
        :model="delStuUser"
        status-icon
        :rules="StuUser"
        ref="delStuUser"
        label-width="0"
        class="demo-ruleForm"
      >
        <el-form-item  label="姓名" :label-width="formLabelWidth">
          <el-input v-model="delStuUser.stuName" disabled></el-input>
        </el-form-item>
        <el-form-item label="学员id" :label-width="formLabelWidth" style="display: none;">
          <el-input v-model="delStuUser.userId"></el-input>
        </el-form-item>
        <el-form-item prop="pass" label="密码" :label-width="formLabelWidth">
          <el-input type="password" v-model="delStuUser.pass" auto-complete="off" placeholder="输入密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="delStuUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="delStuForm('delStuUser')">确 定</el-button>
      </span>
    </el-dialog>
    <el-row style="margin-top:20px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="total,sizes,  prev, pager, next, jumper"
          :total="total"
          class="pag">
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { Register } from '@/api/user.js'
  import { studentManageList, updStuPassword, getUpdate, getUpdateDetail, getAllByIdList, showTreesByRedis, showTreeByRedis, delStuUserByAdmin } from '@/api/training.js'
  export default {
    name: 'Leavefer',
    data () {
    const checkphone = (rule, value, callback) => {
        if (value.length !== 11) {
          callback(new Error('请输入正确的手机号码'))
        } else {
          callback()
        }
      }
      // const checkName = (rule, value, callback) => {
      //   if (value === '') {
      //     callback(new Error('不能为空'))
      //   } else {
      //     callback()
      //   }
      // }
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码必须由小写字母、大写字母、数字、特殊符号'))
        } else {
          if (this.ruleForm2.checkPass !== '') {
            this.$refs.ruleForm2.validateField('checkPass')
          }
          callback()
        }
      }
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.ruleForm2.pass) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        delStuUser: {},
        delStuUserVisible: false,
        userName: '',
        depName: null,
        duty: null,
        stuName: '',
        activeFlag: '',
        statelist: [
          { id: 1, value: '已启用' },
          { id: 2, value: '已禁用' }
        ],
        search: {},
        tableData: [],
        // 干部层次
        AllByIdList: [],
        // 职级
        showTreesByRedisList: [],
        // 单位
        showTreeByRedisList: [],
        optionProps: {
            value: 'label',
            label: 'label',
            children: 'children'
        },
        userId: '',
        total: 0,
        pageNum: 1,
        pageSize: 10,
        adduserVisible: false,
        adduserVisible2: false,
        formLabelWidth: '100px',
        ruleForm2: {
          username: '',
          pass: '',
          checkPass: '',
          phone: ''
        },
        form: {},
        rules2: {
          // username: [{
          //   validator: checkName,
          //   trigger: 'blur',
          //   required: true
          // }],
          pass: [{
            validator: validatePass,
            trigger: 'blur',
            required: true
          }],
          checkPass: [{
            validator: validatePass2,
            trigger: 'blur',
            required: true
          }],
          phone: [{
            validator: checkphone,
            trigger: 'blur',
            required: true
          }]
        },
        rules1: {
          stuName: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
          sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
          stuLeadDuty: [{ required: true, message: '请选择干部层级', trigger: 'change' }],
          depName: [{ required: true, message: '请选择单位名称', trigger: 'change' }],
          duty: [{ required: true, message: '请填写职级', trigger: 'change' }],
          stuBirth: [{ required: true, message: '请选择出生日期', trigger: 'change' }],
          stuPhone: [{ required: true, message: '请填写联系电话', trigger: 'blur' }],
          stuDuty: [{ required: true, message: '请选择职务', trigger: 'blur' }]
        },
        StuUser: {
          pass: [{ required: true, message: '请输入密码', trigger: 'blur' }]
        }
      }
    },
    mounted () {
      this.getOne()
      this.getAllByIdList()
      this.getShowTreesByRedisList()
      this.getShowTreeByRedisList()
    },
    methods: {
      // 干部层次
      getAllByIdList() {
        const id = 300
        getAllByIdList(id).then(res => {
          this.AllByIdList = res.data.data
        })
      },
      // 干部职级
      getShowTreesByRedisList() {
        showTreesByRedis().then(res => {
          this.showTreesByRedisList = res.data.data.dictionaryTrees
        })
      },
      // 单位
      getShowTreeByRedisList() {
        showTreeByRedis().then(res => {
          this.showTreeByRedisList = res.data.data.dictionaryTrees
        })
      },
      // 干部职级选择事件
      handleChange(e) {
        console.log('干部职级选择事件')
      },
      // 学员信息列表
      studentManageList() {},
       // 新增学员-提交
      submitForm (ruleForm2) {
        this.$refs[ruleForm2].validate(valid => {
          if (valid) {
            const from = {
              password: this.ruleForm2.checkPass,
              tel: this.ruleForm2.phone,
              username: this.ruleForm2.phone
            }
              Register(
                from
              ).then((resp) => {
                if (resp.data.code === '0') {
                  this[ruleForm2] = {}
                  this.$notify({
                    title: '成功',
                    message: '新增成功。',
                    type: 'success'
                  })
                  this.getOne()
                  this.adduserVisible = false
                } else if (resp.data.code === '406') {
                  this.$notify({
                    title: '错误',
                    message: resp.data.msg,
                    type: 'error'
                  })
                } else if (resp.data.code === '405') {
                  this.$notify({
                    title: '错误',
                    message: '账号已经存在',
                    type: 'error'
                  })
                } else {
                  this.$notify({
                    title: '错误',
                    message: '服务器请求出错',
                    type: 'error'
                  })
                }
              })
          }
        })
      },
      // 提交修改按钮
      submitUpdate(form) {
        console.log(this.form.duty)
        const a = typeof this.form.duty
        const b = typeof this.form.depName
        console.log(a)
        console.log(b)
        if (a === 'object') {
          this.form.duty = this.form.duty.join('>')
        }
        if (b === 'object') {
          this.form.depName = this.form.depName.join('>')
        }
        this.$refs[form].validate(valid => {
          if (valid) {
            console.log(this.form.duty)
            console.log(this.form.depName)
            getUpdate(this.form).then(res => {
              if (res.data.code === '200') {
                this[form] = {}
                this.$notify({
                  title: '成功',
                  message: '修改成功。',
                  type: 'success'
                })
                this.adduserVisible2 = false
              }
            })
          }
        })
      },
      // 修改
      update(row) {
        console.log(row)
        const id = row.stuId
        getUpdateDetail(id).then(res => {
          this.form = res.data.data
          if (this.form.duty) {
            this.form.duty = res.data.data.duty.split('>')
          }
          if (this.form.depName) {
            this.form.depName = res.data.data.depName.split('>')
          }
          this.adduserVisible2 = true
        })
      },
      // 取消按钮
      cancel() {
        this.adduserVisible2 = false
      },
      // 重置密码
      password(userId) {
        this.userId = userId
        updStuPassword(this.userId).then(res => {
         if (res.data.code === '200') {
            this.$notify({
                  title: '成功',
                  message: '密码重置成功。',
                  type: 'success'
            })
            this.getOne()
          }
        })
      },
      // 新增学员
      adduser() {
        this.adduserVisible = true
      },
      // 用户信息-删除弹窗
      userDelete (row) {
        console.log(row)
        this.delStuUser = row
        this.delStuUserVisible = true
      },
      // 用户信息-删除
      delStuForm(delStuUser) {
        this.$refs[delStuUser].validate(valid => {
          if (valid) {
            const query = {
              username: this.delStuUser.loginName,
              stuUserId: this.delStuUser.userId,
              password: this.delStuUser.pass
            }
              delStuUserByAdmin(query).then((resp) => {
                if (resp.data.code === '200') {
                  this[delStuUser] = {}
                  this.$notify({
                    title: '删除成功',
                    type: 'success'
                  })
                  this.getOne()
                  this.delStuUserVisible = false
                } else {
                  this.$notify({
                    title: resp.data.msg,
                    type: 'error'
                  })
                }
              })
            }
        })
      },
      // 查询
      getOne() {
        this.getShowTreeByRedisList()
        this.search = {
          depName: this.depName,
          stuName: this.stuName,
          loginName: this.userName,
          activeFlag: this.activeFlag,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        studentManageList(this.search).then(res => {
          if (res.data.code === '200') {
            this.tableData = res.data.data.rows
            this.total = res.data.data.total // 查询总条数
          }
        })
      },
      // 清空
      reset() {
        this.depName = ''
        this.stuName = ''
        this.userName = ''
        this.activeFlag = ''
      },
      // 页面
      handleSizeChange(e) {
        this.pageSize = e
        this.getOne()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.getOne()
      }
    }
  }
</script>

<style scoped>
  .boxUs{margin-top: -10px}
  .dash-board {
    display: flex;
    /*left: 10px;*/
    background-color: white;
    height: 120px;
  }
  .bg{margin-top: 15px; padding: 20px; background-color: white;}
  .inquire{
    position: absolute;
    top: 138px;
    left:780px;
  }
  .reset{
    position: absolute;
    top: 138px;
    left: 835px;
  }
  .in1{
    width: 150px;
    position: absolute;
    top: 88px;
    left: 290px;
  }
  .in2{
    width: 215px;
    position: absolute;
    top: 90px;
    left: 270px;
  }
  .in3{
    width: 150px;
    position: absolute;
    top: 120px;
    left: 250px;
  }
  .in4{
    width: 150px;
    position: absolute;
    top: 120px;
    left: 480px;
  }
  .name{
    font-size: 15px;
    position: absolute;
    top: 85px;
    left: 210px;
  }
  .one{
    font-size: 15px;
    position: absolute;
    top: 88px;
    left: 470px;
  }
  .iocpn{
    font-size: 15px;
    position: absolute;
    top: 115px;
    left: 210px;
  }
  .two{
    font-size: 15px;
    position: absolute;
    top: 115px;
    left: 425px;
  }
  .time{
    font-size: 15px;
    position: absolute;
    top: 135px;
    left: 210px;
  }
  .data{
    position: absolute;
    top: 140px;
    left: 270px;
    width: 215px;
  }
  .nameTwo{position: absolute; top:135px; left: 510px}
  .zhuangtai{position: absolute; top:85px; left: 510px}
  .opst3{ position: absolute; top:90px;left: 550px}
  .opst4{ position: absolute;width: 215px;top: 140px;left: 550px}
  .adduser{ position: absolute; top: 138px; left: 900px;}
</style>
